declare const window: any
// @ts-ignore
import $ from 'jquery'
// @ts-ignore
window.$ = $
// @ts-ignore
window.self.$ = $

// css一律引用在這, 如果寫在app.vue會導致打包後css引入順序跟開發時不一致而跑版
import "@/assets/sass/index.sass"
import "@/assets/sass/index.scss"

import Vue from 'vue'
// @ts-ignore
import App from './App.vue'
import store from './store'
import bootstrap from 'modules/bootstrap'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
class website {
  async start() {
    window.bootstrap = bootstrap
    await window.bootstrap.init()
    await this.startVue()
  }

  async startVue() {
    const router = (await import('./router')).default
    window.rootVm = new Vue({
      router,
      store,
      render: h => h(App),
      computed: {
        routeProviderId() {
          return this.$route.params.providerId;
        },
      },
      async created() {

      },
      methods: {
      },
    }).$mount('#app')
  }
}

(new website()).start()
