<template>
  <nav class="navbar navbar-expand-lg navbar-light">
      <div class="navbarBox container-fluid d-flex justify-content-lg-center">
          <div class="collapse navbar-collapse">
              <ul class="mb-2 navbar-nav mb-lg-0">
                  <li class="nav-item">
                      <span class="nav-link" @click="goProductList">商品分類</span>
                  </li>
                  <li class="nav-item">
                      <span class="nav-link" @click="goAboutus">關於我們</span>
                  </li>
                  <li class="nav-item">
                      <span class="nav-link" @click="goNews">最新消息</span>
                  </li>
                  <li class="nav-item">
                      <span class="nav-link" @click="goContactus">聯絡我們</span>
                  </li>
              </ul>
          </div>
          <div class="collapse navbar-collapse headerMobile" :class="{'show': mobileNavShow}" id="navbarContent">
              <ul class="mb-2 navbar-nav mb-lg-0">
                  <li class="nav-item">
                      <span class="nav-link" @click="goProductList">商品分類</span>
                  </li>
                  <li class="nav-item">
                      <span class="nav-link" @click="goAboutus">關於我們</span>
                  </li>
                  <li class="nav-item">
                      <span class="nav-link" @click="goNews">最新消息</span>
                  </li>
                  <li class="nav-item">
                      <span class="nav-link" @click="goContactus">聯絡我們</span>
                  </li>
              </ul>
          </div>

          <div class="collapse navbar-collapse shoppingCartBox" id="shoppingCartBox">
              <div class=" d-flex justify-content-between align-items-center shoppingCartTop">
                  <h1 class="title">購物車</h1>
                  <button class="navbar-toggler shoppingCart" type="button" data-bs-toggle="collapse" data-bs-target="#shoppingCartBox"
                      aria-controls="shoppingCartBox" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="ri-close-line"></i>
                  </button>
              </div>
              <div class="shoppingCartItemBox">
                  <div class="shoppingCartItem d-flex align-items-center">
                      <div class="d-flex justify-content-center align-items-center shoppingCartItemImgBox">
                          <img src="@/assets/img/product/231711000-1.png" alt="" class="shoppingCartItemImg" width="100%">
                      </div>  
                      <div class="shoppingCartItemText d-flex flex-grow-1 justify-content-between">
                          <div class="d-flex">
                              <div class="name">花椰菜</div>
                              <div class="count">100個</div>
                          </div>
                          <div class="remove">
                              <button class="btnRemove">
                                  <i class="ri-close-circle-line"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
          <span class="navbarBrand" @click="goIndex"><img class="logo" :src="logo" alt=""></span>
          <div class="d-flex">
              <!-- <div class="">
                  <button class="navbar-toggler shoppingCart" type="button" data-bs-toggle="collapse" data-bs-target="#shoppingCartBox"
                      aria-controls="shoppingCartBox" aria-expanded="false" aria-label="Toggle navigation">
                      <i class="ri-shopping-bag-line"></i>
                  </button>
              </div>
              <div class="">
                  <a href="" class="memberLogin">
                      <i class="ri-user-line"></i>
                  </a>
              </div> -->
              <button @click="mobileNavShow = !mobileNavShow" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent"
                  aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i class="ri-menu-line"></i>
              </button>
          </div>
      </div>
  </nav>
</template>
<script lang="babel" type="text/babel">

export default {
    props: {
        webinfo: Object,
    },
    data: () => ({
        mobileNavShow: false
    }),
    computed: {
        logo(){
            return this.webinfo && this.webinfo.logo[0] && this.webinfo.logo[0].size_list.origin.url
        },
    },
    methods: {
        goIndex(){
            if(!this.webinfo) return
            this.$router.push({
                name: 'index',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
        },
        goProductList(){
            if(!this.webinfo) return
            this.mobileNavShow = false
            this.$router.push({
                name: 'product-list',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
        },
        goAboutus(){
            if(!this.webinfo) return
            this.mobileNavShow = false
            this.$router.push({
                name: 'about-us',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
        },
        goNews(){
            if(!this.webinfo) return
            this.mobileNavShow = false
            this.$router.push({
                name: 'news',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
        },
        goContactus(){
            if(!this.webinfo) return
            this.mobileNavShow = false
            this.$router.push({
                name: 'contact-us',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
        },
    },

}
</script>