import { EagleApiInterface, EagleModuleApiInterface } from "kernel/api/api";
import qs from "qs";
export default (api: EagleApiInterface) => {
  api.collection.baseApi = <EagleModuleApiInterface>{
    getProviderWeb: (providerId, params) =>
      api.request({
        url: `/api/website/provider/${providerId}`,
        type: "get",
        params,
      }),
    getProviderInfo: (providerId) =>
      api.request({
        url: `/api/provider/public/${providerId}`,
        type: "get",
      }),
    getProductListCategory: (providerId, params) =>
      api.request({
        url: `/api/website/provider/${providerId}/product-menu/index`,
        type: "get",
        params,
      }),
    getProductList: (providerId, params) =>
      api.request({
        url: `/api/website/product/${providerId}/index`,
        type: "get",
        params,
      }),
    getProductDetail: (productId, params) =>
      api.request({
        url: `/api/website/product/${productId}`,
        type: "get",
        params,
      }),
  }
};
