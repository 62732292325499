import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import { mapGetters } from 'vuex'
import api from 'kernel/api/api'
import eagleEnv from 'config/env.json'
Vue.prototype.$api = api(eagleEnv)

export default mixins().extend({
  methods: {
    async initClient() {
      this.$store.dispatch('loading/active')
      // @ts-ignore
      await this.initKernel()
      // @ts-ignore
      this.$store.dispatch('loading/close')
    },
    async initKernel() {
      window.helper.setupVueModel(this)
      this.$api.setup(this)
      // this.$tokenStore.setupApi(this.$api)
      await this.$helper.delay(0.3)
    },
  },
  computed: {
    ...mapGetters({
    }),
    routeProviderId() {
      return this.$route.params.providerId
    },
  },
  components: {
    
  },
})
