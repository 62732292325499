import Vue from 'vue'

class moduleIndex {
  private vm: Vue|undefined
  public name: string
  constructor() {
    this.name = 'base'
  }
  
}

export default new moduleIndex()
