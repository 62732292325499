<template>
  <div class="footer">
      <div class="container-fluid footerTop">
          <div class="d-flex justify-content-between align-items-center flex-md-row flex-column">
              <div class="footerLogoBox" v-if="logo">
                  <span @click="goIndex"><img class="logo" :src="logo" alt=""></span>
              </div>
              <div class="campanyInfo d-flex flex-wrap align-items-center">
                  <p class="mb-0 name" v-if="name">{{ name }}</p>
                  <p class="mb-0 contact" v-if="phone">聯絡電話 : {{ phone }}</p>
              </div>
          </div>
      </div>
      <div class="container-fluid footerBottom">
          <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex footerSocial">
                  <span v-if="fb" @click="goFb" class="footerSocialBtn"> <img src="@/assets/img/social-icon-facebook.svg" alt=""> </span>
                  <span v-if="ig" @click="goIg" class="footerSocialBtn"> <img src="@/assets/img/social-icon-instagram.svg" alt=""> </span>
                  <span v-if="youtube" @click="goYoutube" class="footerSocialBtn"> <img src="@/assets/img/social-icon-youtube.svg" alt=""> </span>
              </div>
              <div class="d-flex copyRight">
                  <a @click="goTerms" class="copyRightItem">服務條款</a>
                  <a @click="goPrivacy" class="copyRightItem">隱私權政策</a>
              </div>
          </div>
      </div>
  </div>
</template>
<script lang="babel" type="text/babel">

export default {
    props: {
        webinfo: Object,
    },
    data: () => ({
        
    }),
    computed: {
        fb(){
            return this.webinfo && this.webinfo.fb
        },
        ig(){
            return this.webinfo && this.webinfo.ig
        },
        youtube(){
            return this.webinfo && this.webinfo.youtube
        },
        logo(){
            return this.webinfo && this.webinfo.logo[0] && this.webinfo.logo[0].size_list.origin.url
        },
        name(){
            return this.webinfo && this.webinfo.name
        },
        phone(){
            return this.webinfo && this.webinfo.phone
        },
    },
    methods: {
        goFb(){
            window.open(this.fb, '_blank')
        },
        goIg(){
            window.open(this.ig, '_blank')
        },
        goYoutube(){
            window.open(this.youtube, '_blank')
        },
        goTerms(){
            if(!this.webinfo) return
            this.$router.push({
                name: 'service-terms',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
            this.scrollToTop()
        },
        goPrivacy(){
            if(!this.webinfo) return
            this.$router.push({
                name: 'service-privacy',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
            this.scrollToTop()
        },
        goIndex(){
            if(!this.webinfo) return
            this.$router.push({
                name: 'index',
                params: {
                    providerId: this.webinfo.provider_id
                },
            })
            this.scrollToTop()
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' 
            })
        }
    },

}
</script>