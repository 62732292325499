import Vue from 'vue'
import deepmerge from 'deepmerge'

class bootstrap {
  protected modules: any
  protected routes: any
  protected menu: any
  protected vm: Vue|null
  constructor() {
    this.vm = null
    this.modules = {}
    this.routes = {}
    this.menu = {}
  }

  /**
   * 自動合併modules底下每個有index.ts的目錄到this.modules
   */
  async init() {
    const moduleConfigs = require.context('modules', true, /(.*)\/index.ts$/i)
    moduleConfigs.keys().forEach(key => {
      const module = moduleConfigs(key).default
      this.modules[module.name] = module
    })
  }

}

export default new bootstrap()