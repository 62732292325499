
// @ts-ignore
import siteConfig from '@/config/site.js';

interface State {
  layoutReady: boolean;
}

export default {
  namespaced: true,
  state: <State>{
    layoutReady: false,
  },
  mutations: {
    // setupLayoutReady: (state: State, status: boolean): void => {
    //   state.layoutReady = status;
    // },
  },
  actions: {
    // setupLayoutReady: (
    //   context: ActionContext<string, boolean>,
    //   status: boolean
    // ): void => {
    //   context.commit('setupLayoutReady', status);
    // },
  },
  getters: {
    // application: (state: State): ApplicationInterface | null =>
    //   state.application,
  },
};
