
import Vue from 'vue';
// @ts-ignore
import header from '@/modules/base/components/header.vue';
// @ts-ignore
import footer from '@/modules/base/components/footer.vue';
import initClient from '@/app/initClient';
import mixins from 'vue-typed-mixins';

export default mixins(initClient).extend({
  components: {
    'header-bar': header,
    'footer-bar': footer
  },
  data: () => ({
    providerId: 0,
    webinfo: null
  }),
  computed: {
    
  },
  watch: {
    $route (val, old) {
      this.providerId = val.params.providerId
    },
    providerId (val) {
      if(val) this.getProviderWeb()
    }
  },
  created() {

  },
  async mounted() {

  },
  
  methods: {
    async getProviderWeb() {
      let result = null
      let result2 = null
      try {
        console.log(this.$route, this.$route.params.providerId)
        // @ts-ignore
        result = await this.$api.collection.baseApi.getProviderWeb(this.$route.params.providerId)
        result.main_banner = result.banner.filter((v: { type: any; }) => v.type == 'MAIN')
        result.sub_banner = result.banner.filter((v: { type: any; }) => v.type == 'SECOND')
        result2 = await this.$api.collection.baseApi.getProviderInfo(this.$route.params.providerId)
        this.webinfo = Object.assign(result, result2)
      } catch (error) {
        console.warn(error)
      }
    },
  }
});
